import { render, staticRenderFns } from "./CardReviewContainer.vue?vue&type=template&id=1d6c7f81"
import script from "./CardReviewContainer.vue?vue&type=script&lang=js"
export * from "./CardReviewContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports