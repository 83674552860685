// import apiService from '../../services/api/_api.mock'
import apiService from '@/services/api'

export default {
  async loginUser({ commit }, { email, name }) {
    const response = await apiService.upsertUserInfo({ email, name }).catch(err => err.response)

    if (response.status === 200) {
      commit('SET_USER', response.data)
    } else {
      response.data = null
    }
    return response.data
  },
  async clearLoginInfo({ commit }) {
    commit('CLEAR_USER_INFO')
  },
  async verifyAuth() {
    return apiService.verifyAuth()
  },
  async changeStudyDateCount({ commit }, { studyDateCount }) {
    const response = await apiService.changeStudyDateCount(studyDateCount)

    console.log('[ACTION:changeStudyDateCount]', response)
    if (response.statue === 200) {
      commit('SET_STUDY_DATE_COUNT', response.data)
    }

    return
  },
}
