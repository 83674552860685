<template>
  <div>
    <card-review-container />
  </div>
</template>

<script>
import CardReviewContainer from '@/components/containers/CardReviewContainer.vue'
export default {
  name: 'CardReview',
  components: {
    CardReviewContainer,
  }
}
</script>

<style>

</style>