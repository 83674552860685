export class GISService {
  #clientId
  #redirectUri
  #uxMode
  #scope

  constructor({ clientID, redirectUri, uxMode = 'redirect', scope } = {}) {
    this.#clientId = clientID || process.env.VUE_APP_GOOGLE_CLIENT_ID
    this.#redirectUri = redirectUri || process.env.VUE_APP_GOOGLE_REDIRECT_URI
    this.#scope = scope || process.env.VUE_APP_GOOGLE_SCOPE

    this.#uxMode = uxMode
  }

  requestCode() {
    if (!window.google) {
      return console.warn('google is not loaded')
    }

    const client = this.#initCodeClient()
    client.requestCode()
  }

  #initCodeClient() {
    return window.google.accounts.oauth2.initCodeClient({
      client_id: this.#clientId,
      ux_mode: this.#uxMode,
      scope: this.#scope,
      redirect_uri: this.#redirectUri,
      state: 'memorized',
    })
  }
}
