import apiService from '@/services/api'
import Cookies from 'js-cookie'
const AUTH_COOKIE_KEY = 'connect.sid'

const singleton = Symbol()
const singletonEnforcer = Symbol()

class AuthService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton')
    }
  }

  isAuthenticated() {
    return !!Cookies.get(AUTH_COOKIE_KEY)
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new AuthService(singletonEnforcer)
    }
    return this[singleton]
  }

  async logout() {
    this.user = null
    await apiService.logout()
  }
}

/**
 * @type AuthService
 */
const authService = AuthService.instance
export default authService
