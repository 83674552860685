<template>
  <div>
    <UserInfoContainer />
    <router-view />
  </div>
</template>

<script>
import UserInfoContainer from '@/components/containers/UserInfoContainer.vue'
export default {
  name: 'CardManagement',
  components: {
    UserInfoContainer,
  },
}
</script>

<style lang="scss" scoped></style>
