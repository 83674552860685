<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="position-absolute w-100 h-100">
    <div class="d-flex justify-content-center align-items-center h-100 bg-light">
      <button
        v-if="!isLoginFailed"
        id="buttonDiv"
        type="x-smartling-placeholder"
        state="what's this"
        theme="filled_black"
        class="my-auto"
        @click="requestCode"
      >
        LOGIN
      </button>
      <div v-else>
        {{ $t('login_failed_title') }}
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { GISService } from '@/services/auth/gis-service'

const userModule = createNamespacedHelpers('user')

export default {
  data() {
    return {
      isLoginFailed: false,
    }
  },
  async mounted() {
    const user = await this.checkSession()
    if (user) {
      await this.loginUser(user)
      this.$router.push({ name: 'Home' })
    } else this.gidService = new GISService()
  },
  methods: {
    ...userModule.mapActions(['loginUser', 'verifyAuth']),
    async checkSession() {
      const response = await this.verifyAuth()
      return response.data
    },
    requestCode() {
      this.gidService.requestCode()
    },
    async signout() {
      const authInstance = window.gapi.auth2.getAuthInstance()
      await authInstance.signOut()
    },
  },
}
</script>

<style lang="scss" scoped>
#buttonDiv {
  width: 200px;
  height: 50px;
}
.login-failure-text {
  font-size: 1.5rem;
}
</style>
