<template>
  <div>
    <div class="study-date-count-area">
      <span class="badge">STUDY <small>th</small></span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const userModule = createNamespacedHelpers('user')

export default {
  name: 'UserInfoContainer',
  data() {
    return {
      userStudyDateCount: this.studyDateCount,
    }
  },
  computed: {
    ...userModule.mapGetters(['studyDateCount']),
  },
  methods: {
    saveStudyDateCount(studyDateCount) {
      console.log('saveStudyDateCount', studyDateCount)
    },
  },
}
</script>

<style lang="scss" scoped>
.study-date-count-area {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  opacity: 0.7;
  font-size: 1.3rem;
  font-weight: 900;
  background-color: blueviolet;
}
</style>
