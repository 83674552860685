export default {
  studyDateCount(state) {
    console.warn('studyDateCount', state.studyDateCount)
    return state.studyDateCount
  },
  name(state) {
    return state.name
  },
  uuid(state) {
    return state.uuid
  }
}
